import { IParamsDeviceProps, IParamsFormAccount, IParamsFormFamilyMembers, IParamsLinkAppProps, IParamsYourMembers } from "@/types/account";
import { fetchAPI } from "../config/request";

class AccountsAPI {
    async getProfile() {
        return await fetchAPI<IParamsFormAccount>(`/api/accounts/profile`);
    }

    async updateProfile(body: any) {
        return await fetchAPI<any>('/api/accounts/profile', {
            method: "PUT",
            body: JSON.stringify(body),
            headers: {
                'Content-Type': 'aplication/json'
            }
        });
    }

    async updatePassword(body: any) {
        return await fetchAPI<any>('/api/accounts/password', {
            method: "PUT",
            body: JSON.stringify(body),
            headers: {
                'Content-Type': 'aplication/json'
            }
        })
    }

    async updateAvatar(body: any) {
        const formData = new FormData();
        formData.append("image", body.image);
        return await fetchAPI<any>("/api/upload", {
            method: 'POST',
            body: formData
        });
    }

    async getFamiliesMembers () {
        return await fetchAPI<IParamsFormFamilyMembers>(`/api/families/members`);
    }

    async addFamiliesMembers (body: any) {
        console.log("count")
        return await fetchAPI<any>(`/api/families/members`, {
            method: "POST",
            body: JSON.stringify(body),
            headers: {
                'Content-Type': 'aplication/json'
            }
        });
    }

    async confirmMembers (payload: any) {
        return await fetchAPI<any>(`/api/families/members/${payload.memberId}/confirm`, {
            method: "PUT",
            body: JSON.stringify({ confirm: payload.confirm }),
            headers: {
                'Content-Type': 'aplication/json'
            }
        });
    }

    async removeFamiliesMembersGroup () {
        return await fetchAPI<any>('/api/families', {
            method: "DELETE",
            headers: {
                'Content-Type': 'aplication/json'
            }
        });
    }

    async leftFamilyGroup () {
        return await fetchAPI<any>('/api/families/members', {
            method: "DELETE",
            headers: {
                'Content-Type': 'aplication/json'
            }
        });
    }

    async getProfileMemberDetail(family_member_id: any) {
        return await fetchAPI<IParamsYourMembers>(`/api/families/members/${family_member_id}/profile`);
    }

    async updateAvatarChild(body: any) {
        const formData = new FormData();
        formData.append("image", body.image);
        return await fetchAPI<any>(`/api/families/members/${body.memberId}/upload`, {
            method: 'POST',
            body: formData
        });
    }

    async deviceLogin(family_member_id: any) {
        return await fetchAPI<IParamsDeviceProps>(`/api/families/members/${family_member_id}/histories?offset=0&limit=20&actions=login`);
    }

    async listLinkApp(family_member_id: any) {
        return await fetchAPI<IParamsLinkAppProps>(`/api/families/members/${family_member_id}/merchants?offset=0&limit=20`);
    }

    async listOperationHistory(family_member_id: any) {
        return await fetchAPI<IParamsLinkAppProps>(`/api/families/members/${family_member_id}/histories?offset=0&limit=20&actions=payment&actions=account`);
    }

    async deviceLogout(body: any) {
        return await fetchAPI<any>(`/api/families/members/${body.memberId}/logout`, {
            method: 'POST',
            body: JSON.stringify({
                sessionId: body.sessionId,
                appId: body.appId,
                all: body.all,
                active: body.active
            }),
            headers: {
                'Content-Type': 'aplication/json'
            }
        });
    }

    async huyGiamSatBe(family_member_id: any) {
        // /api/families/members/:family_member_id
        return await fetchAPI<any>(`/api/families/members/${family_member_id}`, {
            method: "DELETE",
            headers: {
                'Content-Type': 'aplication/json'
            }
        });
    }

    async updatePasswordMember(body: any) {
        return await fetchAPI<any>(`/api/families/members/${body.family_member_id}/password`, {
            method: "PUT",
            body: JSON.stringify({
                password: body.password,
                new_password: body.new_password
            }),
            headers: {
                'Content-Type': 'aplication/json'
            }
        })
    }

    async NotiMemberJoinGroup() {
        return await fetchAPI<any>(`/api/notifications`)
    }
}

const accountsAPI = new AccountsAPI();

export default accountsAPI;
