import HButton from "@/components/ui/HButton";
import HModal from "@/components/ui/HModal";
import { useLanguage } from "@/context/language";
import React from "react";
import { HCheckIcon } from "../HIcon";

interface IModalSuccessProp {
  onClose?: () => void;
  message?: string;
  title?: string;
}

const HModalSuccess = (props: IModalSuccessProp) => {
  const { messages } = useLanguage();
  return (
    <HModal visible={true} showCloseButton={false}>
      <div className="flex flex-col items-center justify-center p-4">
        <HCheckIcon />
        <div className="mt-4 mb-3 text-3xl font-bold text-primary glx:text-2xl">
          {props.title || messages.success}
        </div>
        <div className="my-2 glx:text-xs">{props.message}</div>
        <HButton
          variant="primary"
          size="md"
          className="px-6 py-2 my-2 uppercase "
          onClick={props.onClose}
          rounded="round-full"
        >
          {messages.close}
        </HButton>
      </div>
    </HModal>
  );
};

export default HModalSuccess;
