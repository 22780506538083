export const HCheckIcon = () => {
  return (
    <svg
      width={112}
      height={112}
      viewBox="0 0 112 112"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <circle cx={56} cy={56} r={56} fill="#35D927" />
      <g filter="url(#filter0_di_8706_26125)">
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M86.2062 45.9403L52.3593 80.0374C51.5464 80.8567 50.4787 81.2607 49.4137 81.2551C48.3473 81.2607 47.2796 80.8567 46.4667 80.0374L27.4374 60.8678C25.8226 59.2405 25.8226 56.6046 27.4374 54.9787L33.2831 49.0883C34.8978 47.4624 37.5155 47.4624 39.1289 49.0883L49.4137 59.4481L74.5147 34.1608C76.1281 32.5349 78.7458 32.5349 80.3605 34.1608L86.2062 40.0513C87.8209 41.6772 87.8209 44.3131 86.2062 45.9403Z"
          fill="white"
        />
      </g>
      <defs>
        <filter
          id="filter0_di_8706_26125"
          x="26.2263"
          y="32.9414"
          width="61.1909"
          height="49.014"
          filterUnits="userSpaceOnUse"
          colorInterpolationFilters="sRGB"
        >
          <feFlood floodOpacity={0} result="BackgroundImageFix" />
          <feColorMatrix
            in="SourceAlpha"
            type="matrix"
            values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
            result="hardAlpha"
          />
          <feOffset dy="0.7" />
          <feColorMatrix
            type="matrix"
            values="0 0 0 0 0.171919 0 0 0 0 0.716667 0 0 0 0 0.125417 0 0 0 1 0"
          />
          <feBlend
            mode="normal"
            in2="BackgroundImageFix"
            result="effect1_dropShadow_8706_26125"
          />
          <feBlend
            mode="normal"
            in="SourceGraphic"
            in2="effect1_dropShadow_8706_26125"
            result="shape"
          />
          <feColorMatrix
            in="SourceAlpha"
            type="matrix"
            values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
            result="hardAlpha"
          />
          <feOffset dy={-1} />
          <feComposite in2="hardAlpha" operator="arithmetic" k2={-1} k3={1} />
          <feColorMatrix
            type="matrix"
            values="0 0 0 0 0.554682 0 0 0 0 1 0 0 0 0 0.516667 0 0 0 1 0"
          />
          <feBlend
            mode="normal"
            in2="shape"
            result="effect2_innerShadow_8706_26125"
          />
        </filter>
      </defs>
    </svg>
  );
};
