import HLoading from "@/components/ui/HLoading";
import { useAuth } from "@/context/auth";
import { IError } from "@/types/error";
import { PropsWithChildren, useEffect } from "react";
import HFooter from "../HFooter";
import HModalError from "../HModalError";
import HModalSuccess from "../HModalSuccess";
import HNavbar from "../HNavbar";
import useSWR from "swr";
import accounts from "@/services/module/accounts";
import { useUser } from "@icanid/icanid-sdk-nextjs/client";

const HAppLayout: React.FC<
  PropsWithChildren<{
    center?: boolean;
    isLoading?: boolean;
    error?: IError | null;
    success?: any | null;
    onClose?: any;
  }>
> = ({ center, children, isLoading, error, success, onClose }) => {
  // const { data } = useSWR("/api/accounts/profile", accounts.getProfile, {
  //   revalidateOnMount: true,
  //   revalidateOnFocus: false,
  //   revalidateOnReconnect: false,
  // });
  const { user } = useUser();

  return (
    <>
      <div className="flex flex-col min-h-screen">
        <HNavbar user={user} />
        <main
          className={`bg-[#E1F1FF] flex-1  ${
            center ? "flex flex-col justify-center items-center" : ""
          }`}
        >
          <div className="container py-8 mobile:py-4">
            {isLoading ? (
              <div className="absolute !top-0 left-0 right-0">
                <HLoading />
              </div>
            ) : (
              children
            )}
          </div>
        </main>
        <HFooter />
      </div>

      {error && (
        <HModalError
          message={error.reason || error.message}
          onClose={onClose}
          hiddenButton={error.message == 'CODE_VERIFY_DUE'} 
        />
      )}
      {success && <HModalSuccess message={success.message} onClose={onClose}/>}
    </>
  );
};

export default HAppLayout;
