import { useLanguage } from "@/context/language";
import React from "react";

import loading from "../../../assets/loading.gif";
import HImage from "../HImage";

const HLoading = () => {
  const { messages } = useLanguage();
  return (
    <div className="relative w-full h-screen px-6 py-4 mx-auto text-center bg-opacity-60">
      <div className="absolute left-0 right-0 top-1/3 bg-[#F1F2F3] w-[342px] h-[223px] mx-auto p-4">
        <div className="text-center">
          <HImage
            src={loading}
            width={140}
            height={140}
            layout="intrinsic"
            alt="ICANID"
            className="mx-auto"
          />
        </div>
        <div className="mt-2 text-xl font-semibold text-primary">
          {`${messages.loading} . . .`}
        </div>
      </div>
    </div>
  );
};

export default HLoading;
