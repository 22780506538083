import * as Sentry from "@sentry/nextjs";

export async function fetchAPI<T>(url: string, options?: RequestInit): Promise<T | any> {
    try {
        const response = await fetch(`${process.env.NEXT_PUBLIC_PROXY}${url}`, {
            ...options,
        });
        if (response.status !== 200) {
            const errorResponse = await response.json();
            console.log(errorResponse, "errorResponse")
            if (errorResponse?.status === 401 || errorResponse?.status === 404) {
                window.location.href = '/api/auth/login'
            }
            return errorResponse;
        }

        return await response.json();
    } catch (error) {
        fetch("https://tracking.stag.icanx.vn/logs/", {
            method: "POST",
            headers: {
                "Content-Type": "application/json",
            },
            body: JSON.stringify({
                service: "im-id",
                log_type: "ERROR",
                env: process.env.NEXT_PUBLIC_ENV || '',
                data: error
            }),
        });
        Sentry.captureException(error);
        return { data: null, error: "Đã có lỗi xảy ra! Vui lòng thử lại", isError: true };
    }
}
